import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pulse';
  loading = false;
  constructor(private router: Router) {
    
    this.router.events.subscribe(evt => {
      if(evt instanceof RouteConfigLoadStart) {
        if (evt.route.path == ''){
          setTimeout(() => {
          }, 500);
          this.loading = true;
        } else return false;
      } else if (evt instanceof RouteConfigLoadEnd) {
        this.loading = false;
      }
    });
   }
   
}
