import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, ObservedValueOf } from 'rxjs';

import { environment } from '../../environments/environment';
import { ApiConstants } from '../shared/api-config/api.constants';
import { TrimVideo } from '../shared/models/trim-video';
import { Creatives } from '../shared/models/creatives';
import { Login } from '../shared/models/login';
import { UploadVideo } from '../shared/models/upload-video';

@Injectable({
  providedIn: 'root'
})
export class FetchDataService {
  baseUrl = environment.url;
  channelsApi = ApiConstants.channels;
  wdAudioApi = ApiConstants.wdAudio;
  wdVideoApi = ApiConstants.wdVideo;
  epgApi = ApiConstants.epg;
  epgChannels = ApiConstants.epgChannels;
  epgList = ApiConstants.epgList;
  creatives = ApiConstants.creatives;
  creativesFormSearch = ApiConstants.creativesFormSearch;
  creativesUploadNames = 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/detection/GetCreatives';
  updateCreatives = ApiConstants.updateCreatives;
  deleteCreatives = ApiConstants.deleteCreatives;
  // trimVideo = ApiConstants.trimVideo;
  trimVideo = 'https://borigin.multitvsolution.com:8080/recognizer/pulse/runVideoTrimmer.php';
  // videoCutter = 'https://borigin.multitvsolution.com:8080/recognizer/pulse/runVideoCutter.php';
  videoCutter = 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/InitiateEditVideo';
  commercialBlock = ApiConstants.commercialBlock;
  finishvideo = ApiConstants.finishVideo;
  lists = ApiConstants.lists;
  monitor = ApiConstants.monitor;
  restartMonitor = ApiConstants.restartMonitor;
  login = ApiConstants.login;

  categories = ApiConstants.categories;
  advertisers = ApiConstants.advertisers;
  advertisersData = ApiConstants.advertisersData;
  brands = ApiConstants.brands;
  brandsData = ApiConstants.brandsData;
  uploadVideoFile = 'https://borigin.multitvsolution.com:8080/recognizer/pulse/manualVideoTrimmer.php';
  catchupChannel = ApiConstants.catchup;
  catchupProgram = ApiConstants.catchupProgram;
  epgForCatchup = ApiConstants.epgList;
  downloads = ApiConstants.downloads;
  downloadEpg = ApiConstants.downloadEpg;
  previewUrl = ApiConstants.previewUrl;
  mergeVideoList = ApiConstants.mergeVideoList;
  uploadVideoMerger = ApiConstants.uploadVideoMerger;

  result = [];

  constructor(private http: HttpClient) { 
    this.result = JSON.parse(localStorage.getItem('result'));
  }

  getAllChannels(parent_id): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.channelsApi}?parent_id=${parent_id}`);
  }

  getChannelsBySearch(parent_id, channels): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.channelsApi}?parent_id=${parent_id}&q=${channels}`);
  }

  getWatchdogAudio(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.wdAudioApi}`);
  }

  getWatchdogVideo(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.wdVideoApi}`);
  }
  
  getEpg(channel, date): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.epgApi}/${channel}/${date}`)
  }

  getEpgChannels(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.epgChannels}`);
  }

  getEpgList(date, token): Observable<any> {
    return this.http.get(`${this.epgList}?date=${date}&token=${token}`);  
  }
  
  getCreatives(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.creatives}`)
  }

  getCreativesSearch(search): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.creatives}?q=${search}`);
  }

  getCreativesPage(num): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.creatives}?page=${num}`);
  }

  getCreativesSearchPage(search, num): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.creatives}?q=${search}&page=${num}`);
  }

  trimVideoSend(trim: TrimVideo) {
    return this.http.post(`${this.trimVideo}`, trim);
  }

  getCommercialBlock(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.commercialBlock}`)
  }

  getCommercialBlockBySearch(search): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.commercialBlock}?q=${search}`);
  }

  getCommercialBlockByPage(page): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.commercialBlock}?page=${page}`)
  }

  getCommercialBlockBySearchPage(search, page): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.commercialBlock}?q=${search}&page=${page}`);
  }

  getCreativeFormSearch(){
    return this.http.get(`${this.baseUrl}/${this.creativesFormSearch}`);
  }

  getCreativeFormSearchNames(item){
    return this.http.get(`${this.baseUrl}/${this.creativesFormSearch}?q=${item}`);
  }

  getCreativesUploadNames(item) {
    return this.http.get(`${this.creativesUploadNames}?q=${item}`);
  }
  
  updateCreativesPage(creative: Creatives): Observable<any> {
    return this.http.post(`${this.baseUrl}/${this.updateCreatives}`, creative )
  }

  deleteCreativesData(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.deleteCreatives}/${id}`)
  }

  getFinishVideo(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.finishvideo}/${id}`)
  }

  getLists(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.lists}`)
  }

  getListsSearch(search): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.lists}?q=${search}`);
  }

  getListsPage(num): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.lists}?page=${num}`);
  }

  getListsSearchPage(search, num): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.lists}?q=${search}&page=${num}`);
  }

  getMonitorList(parent_id): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.monitor}?parent_id=${parent_id}`);
  }
  
  getMonitorBySearch(search): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.monitor}?server=${search}`)
  }

  getRestartMonitor(channel_id): Observable<any> {
    return this.http.get(`${this.restartMonitor}?channel=${channel_id}`);
  }

  options: any = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
  });
  
  // loginPage(user: Login): Observable<any> {
  //   const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
  //   let params = new HttpParams();
  //   params = params.set('email', user.email);
  //   params = params.set('password', user.password);
  //   return this.http.post(`https://staging.multitvsolution.com:9004/automatorapi/v6/crons/login`, params);
  // }

  getCategories() {
    return this.http.get(`${this.baseUrl}/${this.categories}`);
  }
  getCategoriesOnSearch(item: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.categories}?q=${item}`);
  }
  
  getAdvertisers() {
    return this.http.get(`${this.baseUrl}/${this.advertisers}`);
  }

  getAdvertisersOnSearch(item: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.advertisers}?q=${item}`)
  }

  getAdvertisersData(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.advertisersData}?brand_id=${id}`)
  }

  getBrands(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.brandsData}`);
  }

  getBrandsOnSearch(item): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.brandsData}?q=${item}`);
  }

  getBrandsOnSelect(id: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/${this.brands}?advertiser_id=${id}`)
  }

  uploadVideoSend(video) {
    return this.http.post(`${this.uploadVideoFile}`, video);
  }

  getCatchupChannel(channel, datetime, duration) {
    return this.http.get(`${this.catchupChannel}?channel=${channel}&datetime=${datetime}&duration=${duration}`);
  }

  getCatchupProgram(channel, date, time): Observable<any> {
    return this.http.get(`${this.catchupProgram}?channel=${channel}&date=${date}&time=${time}`);
  }

  getEpgForCatchup(): Observable<any> {
    return this.http.get(`${this.epgForCatchup}?token=58f738434ee58`);
  }

  getDownloadsList(): Observable<any> {
    return this.http.get(`${this.downloads}`);
  }

  getDownloadsListWithUserId(user_id, userType, page) {
    return this.http.get(`${this.downloads}?user_id=${user_id}&type=${userType}&page=${page}`);
  }

  downloadEpgVideo(download): Observable<any> {
    return this.http.post(`${this.downloadEpg}`, download);
  }

  getPreviewUrl(channel, datetime, duration) {
    return this.http.get(`${this.previewUrl}?channel=${channel}&datetime=${datetime}&duration=${duration}`);
  }
  
  videoCutterSend(cut) {
    return this.http.post(`${this.videoCutter}`, cut);
  }
  

  getVideoMergerList(){
    return this.http.get(`${this.mergeVideoList}`);
  }

  getVideoMergerListWithUserId(user_id, userType, page){
    return this.http.get(`${this.mergeVideoList}?user_id=${user_id}&type=${userType}&page=${page}`);
  }

  uploadVideoToMerger(video: any): Observable<any> {
    return this.http.post(`${this.uploadVideoMerger}`, video);
  } 
  // notification logs
  getListLogs(){
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/loockout/ListLogs`);
  }
  getListLogsByPage(index){
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/loockout/ListLogs?page=${index}`);
  }
  getListLogsByDateFilter(start, end){
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/loockout/ListLogs?start=${start}&&end=${end}`);  
  }
  addChannelOnMosaicWall(channel:any): Observable<any>{
    return this.http.post(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/loockout/InsertChannel`, channel);
  }
  deleteChannelOnMosaicWall(id:any): Observable<any>{
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/loockout/deleteChannel?channel_id=${id}`);
  }

  addStream(stream):Observable<any>{
    return this.http.post(`http://103.253.175.3:8080/stream/v1/add`, stream);
  }
  stopStream(stream):Observable<any>{
    return this.http.post(`http://103.253.175.3:8080/stream/v1/stop`, stream);
  }

  getKeymomentsdata():Observable<any>{
    let uid = JSON.parse(localStorage.getItem('result'));
    console.log(uid);
    
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/getMoments?uid=${uid[0].user_id}`);
  }
  
  postStreamData(params):Observable<any>{
    return this.http.post(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/addStream`,params);
  }
  editStream(params):Observable<any>{
    return this.http.post(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/editStream`,params);
  }
  getStreamData(uid):Observable<any>{
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/getStreams?uid=${uid}`);
  }
  deleteStream(id,type){
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/deleteStream?stream_id=${id}&type=${type}`);
  }
  pauseStream(id,type){
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/stopStream?stream_id=${id}&type=${type}`);
  }
  deleteMoment(id){
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/deleteMoment?moment_id=${id}`)
  }
  getDashboardData(id){
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/getDashboardStats?uid=${id}`);
  }
  getDashChartData(id){
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/getKeyMomentStats?uid=${id}`);
  }
  getMomentbyStream(id){
    return this.http.get(`https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/getMomentsByStream?stream_id=${id}`);
  }
}
