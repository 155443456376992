export const ApiConstants = {
  login : 'RecognizerApi/index.php/api/multitvshots/authentication',
  channels: 'RecognizerApi/index.php/api/loockout/getChannels',
  epg: 'RecognizerApi/index.php/api/epg/getEpg',
  epgChannels: 'RecognizerApi/index.php/api/loockout/getEPGChannels',
  wdAudio: 'RecognizerApi/index.php/api/detection/WDAudio',
  wdVideo: 'RecognizerApi/index.php/api/detection/WDVideo',
  creatives: 'RecognizerApi/index.php/api/detection/creatives',

  updateCreatives: 'RecognizerApi/index.php/api/Loockout/updateCreatives',
  deleteCreatives: 'RecognizerApi/index.php/api/loockout/deleteCreatives',
  creativesFormSearch: 'RecognizerApi/index.php/api/Loockout/CreativesStatus',
  trimVideo: 'RecognizerApi/ffmpeg',
  commercialBlock: 'RecognizerApi/index.php/api/Detection/cbreaks',
  finishVideo: 'RecognizerApi/index.php/api/Detection/finish',
  lists: 'RecognizerApi/index.php/api/detection/lists',
  monitor: 'RecognizerApi/index.php/api/crons/total',
  restartMonitor: 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/Monitoring/restart',

  categories: 'RecognizerApi/index.php/api/loockout/categories',
  advertisers: 'RecognizerApi/index.php/api/loockout/advertisers',
  advertisersData: 'RecognizerApi/index.php/api/loockout/AdvertisersData',
  brands: 'RecognizerApi/index.php/api/loockout/brands',
  brandsData: 'RecognizerApi/index.php/api/loockout/BrandsData',
  epgList: 'https://streamer.multitvsolution.com/trancoderscript/epg.php',
  catchup: 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/crons/geturl',
  catchupProgram: 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/Loockout/GetProg',
  downloads: 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/multitvshots/ListDownloads',
  downloadEpg: 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/Loockout/SaveToDownloads',
  previewUrl: 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/crons/getPreviewUrl',
  mergeVideoList: 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/Loockout/ListMerged',
  uploadVideoMerger: 'https://streamer.multitvsolution.com/RecognizerApi/index.php/api/Loockout/UploadToMerger',

  sonyTvEpglist: 'https://streamer.multitvsolution.com/trancoderscript/direct_catchup_epg.php',
};